import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';

interface Props {
  data: {
    fileName: {
      childImageSharp: {
        fluid: any;
      };
    };
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
  location: {
    pathname: string;
  };
}

const About = ({ data, location }: Props) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Tory Walker" />
      <div className="outer">
        <div className="inner">
          <div className="site-header-content">
            <h1 className="site-title">
              <Img
                alt="Tory Walker"
                className="home-profile-image"
                fluid={data.fileName.childImageSharp.fluid}
              />
            </h1>
            <h2 className="site-description">Hi! I'm Tory Walker. 👋</h2>
          </div>
        </div>
      </div>
      <div className="outer">
        <div className="inner">
          <article className="post-full post">
            <section className="post-full-content">
              <div className="post-content">
                <p>
                  I'm a software engineer with an insatiable thirst for
                  knowledge.
                </p>
                <p>
                  My journey into software development began when I was 13. My
                  mom and dad bought the family a new Acer desktop, and hooked
                  it up to AOL. After exploring all that Windows 95 had to
                  offer, I started figuring out how to build my own websites. My
                  first was a Pokémon trading card exchange (
                  <em>shoutout to geocities</em>
                  ). I only made one trade, but the experience had me hooked.
                </p>
                <p>
                  It was an easy decision to major in Computer Science at{' '}
                  <a href="https://www.uncc.edu" target="_blank" rel="noopener">
                    UNC Charlotte
                  </a>
                  . It gave me a good understanding of the fundamentals and
                  theorhetical concepts. From there I've applied that knowledge
                  working for the government and multiple private companies,
                  including{' '}
                  <a
                    href="https://redventures.com"
                    target="_blank"
                    rel="noopener"
                  >
                    Red Ventures
                  </a>
                  .
                </p>
                <p>
                  <strong>
                    I love learning new technologies and sharing that knowledge.
                  </strong>{' '}
                  I also love giving back, which is why I've made the decision
                  to learn in public by making my entire site open source. If
                  you find any bugs, or have any suggestions,{' '}
                  <a
                    href="https://github.com/torywalker/torywalker.com/pulls"
                    target="_blank"
                    rel="noopener"
                  >
                    please submit a PR
                  </a>
                  !
                </p>
              </div>
            </section>
          </article>
        </div>
      </div>
    </Layout>
  );
};

export default About;

export const pageQuery = graphql`
  query {
    fileName: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;
